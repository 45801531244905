import React from 'react';
import styled from "styled-components";
import loadmore from "../../src/assets/loadmore.png";
import spa1 from "../../src/assets/spa1.jpg";
import spa2 from "../../src/assets/spa2.jpg";
import spa3 from "../../src/assets/spa3.jpg";
import spa4 from "../../src/assets/spa4.jpg";
import spa5 from "../../src/assets/spa5.jpg";
import spa6 from "../../src/assets/spa6.jpg";
import spa7 from "../../src/assets/spa7.jpg";
import spa8 from "../../src/assets/spa8.jpg";
import spa9 from "../../src/assets/spa9.jpg";
import spa10 from "../../src/assets/spa10.jpg";
import { motion } from "framer-motion";
import { useScroll } from "../../src/components/useScroll";
import { spaAnimations } from "../animation";

function Spa() {
  const [element, controls] = useScroll();
  return (
  <Section id="spa" ref={element}>
    <div className="grid">
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-one grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-two grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-three grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-four grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-five grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-six grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-seven grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-eight grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-nine grid-box"></motion.div>
        <motion.div variants={spaAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-ten grid-box"></motion.div>
    </div>
    <div className="spa-more">
        <span>Load More</span>
        <img src={loadmore} alt="Load More" />
    </div>
  </Section>
  );
}

const Section = styled.section`
min-height: 100vh;
padding-bottom: 2rem;
background-color: var(--secondary-color);
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
  "one one two two"
  "one one three four"
  "five six seven seven"
  "eight six seven seven";
  .grid-box {
    height: 15rem;
    width: 100%;
    cursor: pointer;
    &:nth-of-type(1) {
      grid-area: one;
      background: url(${spa1}) no-repeat right center;
      background-size: cover;
      height: 100%;
      z-index: 10;
    }
    &:nth-of-type(2) {
      grid-area: two;
      background: url(${spa2}) no-repeat right center;
      background-size: cover;
      height: 100%;
      z-index: 10;
    }


    &:nth-of-type(3) {
      grid-area: three;
      background: url(${spa3}) no-repeat right center;
      background-size: cover;
      height: 100%;
      z-index: 10;
    }
    &:nth-of-type(4) {
      grid-area: four;
      background: url(${spa4}) no-repeat center center;
      background-size: cover;
      z-index: 10;
    }
    &:nth-of-type(5) {
      grid-area: five;
      background: url(${spa5}) no-repeat right center;
      background-size: cover;
    }
    &:nth-of-type(6) {
      grid-area: six;
      background: url(${spa6}) no-repeat center center;
      background-size: cover;
    }
    &:nth-of-type(7) {
      z-index: 10;
      grid-area: seven;
      background: url(${spa7}) no-repeat right center;
      background-size: cover;
    }
    &:nth-of-type(8) {
      grid-area: eight;
      background: url(${spa8}) no-repeat center center;
      background-size: cover;
      height: 100%;
    }
    &:nth-of-type(9) {
      grid-area: six;
      background: url(${spa9}) no-repeat right center;
      background-size: cover;
      height: 100%;
    }
    &:nth-of-type(10) {
      grid-area: seven;
      background: url(${spa10}) no-repeat right center;
      background-size: cover;
      z-index: 10;
    }
  }
}
.spa-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 4rem 0;
  span {
    color: #fff;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 280px) and (max-width: 1080px) { 
  .grid {
    padding: 0;
    grid-template-columns: 1fr;
    grid-template-areas:
    "one"
    "two"
    "three"
    "four"
    "five"
    "six"
    "seven"
    "eight"
    "nine"
    "ten";
    .grid-box {
      height: 25rem !important;
    }
  }
}
`;

export default Spa