import React from 'react';
import styled from "styled-components";
import loadmore from "../../src/assets/loadmore.png";
import portfolio11 from "../../src/assets/portfolio11.jpg";
import portfolio12 from "../../src/assets/portfolio12.jpg";
import portfolio13 from "../../src/assets/portfolio13.jpg";
import portfolio14 from "../../src/assets/portfolio14.jpg";
import portfolio15 from "../../src/assets/portfolio15.jpg";
import portfolio16 from "../../src/assets/portfolio16.jpg";
import portfolio17 from "../../src/assets/portfolio17.jpg";
import portfolio18 from "../../src/assets/portfolio18.jpg";
import { motion } from "framer-motion";
import { useScroll } from "../../src/components/useScroll";
import { portfolioAnimations } from "../animation";

function Portfolio() {
  const [element, controls] = useScroll();
  return (
  <Section id="portfolio" ref={element}>
    <div className="grid">
        <motion.div variants={portfolioAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-one grid-box"></motion.div>
        <motion.div variants={portfolioAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-two grid-box"></motion.div>
        <motion.div variants={portfolioAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-three grid-box"></motion.div>
        <motion.div variants={portfolioAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-four grid-box"></motion.div>
        <motion.div variants={portfolioAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-five grid-box"></motion.div>
        <motion.div variants={portfolioAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-six grid-box"></motion.div>
        <motion.div variants={portfolioAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-seven grid-box"></motion.div>
        <motion.div variants={portfolioAnimations} animate={controls} transition={{ delay: 0.03, type: "tween", duration: 0.8 }} className="child-eight grid-box"></motion.div>
    </div>
    <div className="portfolio-more">
        <span>Load More</span>
        <img src={loadmore} alt="Load More" />
    </div>
  </Section>
  );
}

const Section = styled.section`
min-height: 100vh;
padding-bottom: 2rem;
background-color: gray;
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
  "one one two two"
  "one one three four"
  "five six seven seven"
  "eight six seven seven";
  .grid-box {
    height: 15rem;
    width: 100%;
    cursor: pointer;
    &:nth-of-type(1) {
      grid-area: one;
      background: url(${portfolio11}) no-repeat right center;
      background-size: cover;
      height: 100%;
      z-index: 10;
    }
    &:nth-of-type(2) {
      grid-area: two;
      background: url(${portfolio12}) no-repeat center center;
      background-size: cover;
      z-index: 10;
    }
    &:nth-of-type(3) {
      grid-area: three;
      background: url(${portfolio13}) no-repeat right center;
      background-size: cover;
    }
    &:nth-of-type(4) {
      grid-area: four;
      background: url(${portfolio18}) no-repeat center center;
      background-size: cover;
    }
    &:nth-of-type(5) {
      z-index: 10;
      grid-area: five;
      background: url(${portfolio15}) no-repeat right center;
      background-size: cover;
    }
    &:nth-of-type(6) {
      grid-area: six;
      background: url(${portfolio17}) no-repeat center center;
      background-size: cover;
      height: 100%;
    }
    &:nth-of-type(7) {
      grid-area: seven;
      background: url(${portfolio16}) no-repeat right center;
      background-size: cover;
      height: 100%;
    }
    &:nth-of-type(8) {
      grid-area: eight;
      background: url(${portfolio14}) no-repeat right center;
      background-size: cover;
      z-index: 10;
    }
  }
}
.portfolio-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 4rem 0;
  span {
    color: #fff;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 280px) and (max-width: 1080px) { 
  .grid {
    padding: 0;
    grid-template-columns: 1fr;
    grid-template-areas:
    "one"
    "two"
    "three"
    "four"
    "five"
    "six"
    "seven"
    "eight";
    .grid-box {
      height: 25rem !important;
    }
  }
}
`;

export default Portfolio